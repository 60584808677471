<template>
  <v-row no-gutters>
    <v-col
      cols="auto"
      class="d-flex flex-column"
      :class="leader_id == item.id ? 'task-box-leader' : 'task-box-member'"
      :key="`member-${index}`"
      v-for="(item, index) in _taskMember"
    >
      <img class="avatar" :src="item.profile_image ? item.profile_image_url : `${root}images/userblank.png`"/>
      <span :style="{color: text_color}">{{item.last_name}}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TaskShowMember",
  props: ["member", "leader_id", "text_color"],
  data() {
    return {
      root: process.env.VUE_APP_ROOT_API,
    };
  },
  computed: {
    _taskMember() {
      if (!this.member) {
        return []
      }
      let newArr = this.member
      newArr.map((value, key) => {
        if (this.leader_id && this.leader_id === value.id) {
          let temp = newArr[0]
          newArr[0] = value
          newArr[key] = temp
        }
      })
      return newArr
    }
  },
};
</script>

<style lang="scss" scoped>
.task-box {
  &-leader {
    img {
      border: 3px solid #f2994a;
    }
  }

  &-leader,
  &-member {
    img {
      height: 30px;
      width: 30px;
      object-fit: cover;
      border-radius: 50%;
    }
    span {
      font-size: 0.75rem;
      color: #333333;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 30px;
    }
  }
}
</style>