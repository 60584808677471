<template>
  <div>
    <template v-if="customerTasksById.length">
      <div @click="goToDetailTask(item.id)" v-for="(item, index) in customerTasksById" :key="index">
        <div class="cursor-pointer task-box mt-4 pa-5" :style="{
          backgroundColor: item.service.background_color,
          color: item.service.font_color
        }" v-if="project && project.id == item.project_id">
          <v-row dense>
            <v-col cols="12">
              <span class="d-block text-heading-2">{{  getDateFormat(item.date)  }}</span>
              <span class="d-block text-heading-2">
                <span v-if="item.service">{{  item.service.name  }}</span>
              </span>
            </v-col>
            <v-col cols="12" class="mt-1">
              <TaskShowMember :text_color="item.service.font_color" :member="item.task_member"
                :leader_id="item.leader_id" />
            </v-col>
          </v-row>
        </div>
      </div>

    </template>

    <p class="text-center text-body-1 mt-3" v-else>タスクがありません。</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import TaskShowMember from './task/TaskShowMember'
export default {
  name: "BasicInformation",
  props: ["customer", "sites", "project"],
  components: { TaskShowMember },
  computed: {
    ...mapGetters(['customerTasksById'])
  },

  data() {
    return {
      data: {
        site: 0,
      },
    };
  },
  created() {
    this.$store.dispatch('CUSTOMER_TASK_GET', this.customer.id)
  },
  methods: {
    getDateFormat(date) {
      return dayjs(date).format(`YYYY年MM月DD日`)
    },
    goToDetailTask(id) {
      this.$router.push('/task/' + id)
    }
  }
};
</script>

<style lang="scss" scoped>
.task-box {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &.cursor-pointer {
    cursor: pointer;
  }
}
</style>