<template>
  <v-row>
    <v-col cols="12">
      <TaskList :customer="customer" :sites="sites" :project="project" />
    </v-col>
  </v-row>
</template>

<script>
import TaskList from "@/components/layout/customer/TaskList.vue";
export default {
  name: "Task",
  props: {
    project: {
      type: Object,
      default: null,
      required: true
    },
  },
  components: { TaskList },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    },
    sites() {
      return this.customer.sites.map((sites, key) => {
        return { value: key, text: sites.name };
      });
    }
  },
  created() { },
  data() {
    return {
      data: {
        site: 0
      }
    };
  }
};
</script>
